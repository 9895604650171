import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import HeaderUnderLine from "../../atoms/headerUnderline/HeaderUnderLine";
import { FirstLine, SecondLine, Wrapper } from "./RewirAbout.style";

const RewirAbout = () => {
  const [showMore, setShowState] = useState(false);
  const [height, setHeight] = useState();
  const wrapperRef = useRef();

  const getHeight = (ref) => {
    const element = ref.current;
    const height = element.clientHeight + "px";
    return height;
  };

  const onClickHandler = () => {
    setShowState(!showMore);
    const h = getHeight(wrapperRef);
    setHeight(h);
  };

  return (
    <Wrapper showMore={showMore} ref={wrapperRef} style={{ maxHeight: height }}>
      <h2>Wszczęcie egzekucji</h2>
      <HeaderUnderLine />
      <FirstLine isShow={showMore}>
        <p>
          W celu wszczęcia egzekucji należy złożyć wniosek egzekucyjny wraz z
          tytułem wykonawczym w oryginale. Klauzula wykonalności powinna
          zawierać stwierdzenie, że tytuł uprawnia do egzekucji, a w razie
          potrzeby oznaczać jej zakres.
        </p>
        <p>
          We wniosku egzekucyjnym powinno znaleźć się jak najwięcej danych
          dłużnika:
        </p>
        <ul>
          <li>osoba fizyczna: numer dowodu osobistego, Pesel, NIP;</li>
          <li> osoba prawna: numer NIP, Regon, KRS.</li>
        </ul>
        <p>W sposób znaczący skraca to czas prowadzonego postępowania.</p>
        <div>
          <button onClick={onClickHandler}>Pokaż więcej</button>
        </div>
      </FirstLine>
      <SecondLine isShow={showMore}>
        <p>
          Wskazując egzekucję z nieruchomości, powinno dołączyć się informacje:
        </p>
        <ul>
          <li> czy dłużnik jest jej właścicielem/współwłaścicielem;</li>
          <li> nr KW (księgi wieczystej nieruchomości);</li>
          <li>o wprowadzenie w posiadanie nieruchomości;</li>
          <li>dokładne położenie nieruchomości (adres).</li>
        </ul>
        <p>
          Zgodnie art.10 ust.3 ustawy z dnia 22 marca 2018r. o komornikach
          sądowych, Wierzyciel, dokonując wyboru komornika, składa wraz z
          wnioskiem o wszczęcie egzekucji oświadczenie na piśmie, że korzysta z
          prawa wyboru komornika. Wierzyciel jest zobowiązany do wskazania
          majątku dłużnika, z którego może być prowadzona egzekucja. Jeżeli
          wierzyciel nie posiada informacji na temat majątku dłużnika może
          zlecić komornikowi poszukiwanie majątku dłużnika za wynagrodzeniem.
        </p>
        <p>
          Zgodnie z art. 44. Opłata stała za poszukiwanie majątku dłużnika na
          zlecenie wierzyciela, o którym mowa w art. 8012 ustawy z dnia 17
          listopada 1964 r. – Kodeks postępowania cywilnego, wynosi 100 złotych.
        </p>
        <div>
          <button onClick={onClickHandler}>Pokaż mniej</button>
        </div>
      </SecondLine>
    </Wrapper>
  );
};

export default RewirAbout;
