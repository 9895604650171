

export const downloadFilesData = [
  {
    id: 1,
    fileName: "Deklaracja spłaty zadłużenia",
    url: "Deklaracja spłaty zadłużenia.pdf",
  },
  {
    id: 2,
    fileName: "Formularz wniosku o wszczęcie egzekucji",
    url: "Formularz wniosku o wszczęcie egzekucji.pdf",
  },
  {
    id: 3,
    fileName: "Wniosek o wszczęcie egzekucji",
    url: "Wniosek o wszczęcie egzekucji.pdf",
  },
  {
    id: 4,
    fileName: "Wniosek o wprowadzenie w posiadanie nieruchomości",
    url: "Wniosek o wprowadzenie w posiadanie nieruchomości.pdf",
  },
];
