import img1 from "../assets/img/logos/ceidg.png";
import img2 from "../assets/img/logos/epu.png";
import img3 from "../assets/img/logos/gus.png";
import img4 from "../assets/img/logos/ognivo.png";
import img5 from "../assets/img/logos/zus.png";

export const SystemsLogosData = [
  { id: 1, icon: img1 },
  { id: 2, icon: img2 },
  { id: 3, icon: img3 },
  { id: 4, icon: img4 },
  { id: 5, icon: img5 },
];
